<!-- template -->
<template>
  <div id="app">
    <!-- <Nav /> -->

    <div class="container">
      <div class="row">
        <div class="col-md-6 d-none d-md-block">
          <carousel />

          <h5 class="Copyright-2021-Dat">
            Copyright © 2021 Data Poem All rights reserved. Privacy and Legal
            Policies
          </h5>
        </div>
        <div class="col-md-6 p-0">
          <div class="py-0 py-md-5">
            <div class="auth-inner p-2 m-0">
              <div class="content-center" style="height: 40%">
                <div class="row p-0 m-0 w-0">
                  <img src="../assets/bitmap.png" srcset="
                      ../assets/bitmap@2x.png 2x,
                      ../assets/bitmap@3x.png 3x
                    " class="Bitmap mb-5" />
                  <h1 class="Hello-welcome-back w-100 mt-3">
                    Hello, welcome back
                  </h1>
                </div>
              </div>

              <b-form @submit.stop.prevent="onSubmit" class="w-100 px-5">
                <div class="w-100 d-flex mb-5">
                  <b-form-group class="w-100 p-2" id="example-input-group-login">
                    <div class="d-flex w-100 px-0 px-md-5 row m-0">
                      <span class="content-center inputIcon" :class="{
                        invalid: $v.form.email.$error,
                        success:
                          !$v.form.email.$error && $v.form.email.$model == '',
                      }"><img :src="require('@/assets/' + getColor)" alt="userIcon" />
                      </span>
                      <b-form-input style="width: 90%; border-right: 1px solid #3a424f;" :style="
                        form.email === ''
                          ? 'color: #9aafd4'
                          : 'color: #222a37'
                      " type="email" class="form-control c-form-input m-0" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Email Id" name="example-input-1"
                        v-model="$v.form.email.$model" :state="validateState('email')"></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback" class="w-100">Oops! That doesn't seem right.
                        Please check the email
                        ID entered</b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </div>
                <div class="w-100 d-flex">
                  <b-form-group class="w-100 p-2" id="example-input-group-reset">
                    <div class="d-flex w-100 px-0 px-md-5 row m-0">
                      <span class="content-center inputIcon" :class="{
                        invalid: $v.form.password.$error,
                        success:
                          !$v.form.password.$error &&
                          $v.form.password.$model == '',
                      }">
                        <img :src="require('@/assets/' + getPasswordColor)" alt="userIcon" />
                      </span>
                      <b-form-input style="width: 80%; margin-right: -1px !important"
                        :type="showPassword ? 'text' : 'password'" class="form-control c-form-input m-0 pr-0"
                        id="exampleInputPassword1" placeholder="Password" aria-describedby="emailHelp"
                        name="example-input-1" v-model="$v.form.password.$model" :state="validateState('password')">
                      </b-form-input>
                      <span class="content-center inputIcon passwordIcon" :class="{
                        invalid: $v.form.password.$error,
                        success:
                          !$v.form.password.$error &&
                          $v.form.password.$model == '',
                      }"><img v-if="!showPassword" @click="togglePassword" src="../assets/eye-close-line bold.svg"
                          alt="" />
                        <img v-else @click="togglePassword" src="../assets/eye-line.png" alt="" />
                      </span>
                      <b-form-invalid-feedback id="input-1-live-feedback" class="w-100">Invalid Password.
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </div>
                <p style="text-align: center; font-size: small; color: red;" v-if="
                  errorMessage ===
                  'Please enter Correct  Password or Click the below link to reset the password.'
                ">
                  Did you get the password wrong? Try again.
                  <br />
                  If you need help resetting the password, click below link
                </p>
                <p style="text-align: center; font-size: small; color: red;" v-else>
                  {{ errorMessage }}
                </p>
                <div class="w-100 d-flex mb-5" v-if="showOTPBox">
                  <b-form-group class="w-100 p-2" id="example-input-group-login">
                    <div class="d-flex w-100 px-0 px-md-5 row m-0">
                      <b-form-input style="width: 100%; border: 1px solid #3a424f;border-radius: 3px" :style="
                        form.email === ''
                          ? 'color: #9aafd4'
                          : 'color: #222a37'
                      " type="number" class="form-control c-form-input m-0" id="otpInputBox"
                        aria-describedby="emailHelp" placeholder="Enter OTP" name="example-input-1"
                        v-model="otpDetails"></b-form-input>
                      <span class="w-100 mt-2 p-0 " style="color: red;" v-if="showOtpError">Entered OTP is
                        incorrect.</span>
                    </div>
                  </b-form-group>
                </div>
                <span></span>
                <div class="w-100 my-5 px-0 px-md-5">
                  <div class="Forgot-your-password pr-2" style="text-align: end">
                    <span @click="$router.push('/forgot-password')">Forgot your password?</span>
                  </div>
                </div>

                <div class="form-check">
                  <div class="row mt-5 mr-0 ml-0 px-0 px-md-5" style="justify-content: center; align-items: center">
                    <div class="col-12 col-sm-6 col-md-5 mt-3 pl-2">
                      <div class="w-100" style="float: right">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        <label class="form-check-label Remember-me" for="exampleCheck1">Remember me</label>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-7 w-100 pr-0 pr-md-2 pl-0  mt-5 mt-md-3">
                      <div class="w-100 d-flex justify-content-center justify-content-sm-end">
                        <w-button :buttonText="'Login'" class="w-75" :class="{ resetPasswordLink: isDisable }"
                          :isDisable="isDisable" :buttonLoader="buttonLoader" @buttonClicked="onSubmit" float="none">
                        </w-button>
                        <!-- <button class="btn btn-primary" @click="googleOauth">Google</button> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 my-5 px-0 px-md-5">
                  <div class="Forgot-your-password pr-2" style="text-align: center; text-decoration:none;">
                    <!-- <span>Sign Up</span -->
                    <!-- > -->
                    Dont't have account yet?
                    <span style="text-decoration:underline;" @click="$router.push('/sign-up/registration')">Sign up
                    </span>
                    today!
                  </div>
                </div>
                <!-- <div class="w-100"> -->
                <!-- <w-slider></w-slider> -->
                <!-- </div> -->
              </b-form>
              <center>
                <a style="
                    font-size: 15px;
                    color: black;
                    text-decoration: underline;
                  " href="https://datapoem.com/terms-conditions.html">
                  Terms & Conditions</a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import Carousel from "@/components/Login/Carousel.vue";
import { required, email, minLength } from "vuelidate/lib/validators";
import Button from "../components/Profile/Button.vue";
import { LoginServices } from "../services/LoginServices.js";
// import axios from 'axios';
// import Slider from "@/widgets/Slider.vue";
const loginServices = new LoginServices();

export default {
  name: "Login",
  mixins: [validationMixin, formMixin],
  data() {
    return {
      otpDetails: "",
      showOTPBox: false,
      buttonLoader: "normal",
      errors: [],
      errorMessage: "",
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      validForm: false,
      showOtpError: false,
    };
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },

  components: {
    Carousel,
    "w-button": Button,
    // "w-slider": Slider,
  },
  computed: {
    getColor() {
      if (this.form.email !== "" && !this.$v.form.email.$error) {
        return "user-line fill.svg";
      } else if (this.form.email !== "" && this.$v.form.email.$error) {
        return "user-line error.svg";
      }
      return "user-line.svg";
    },
    getPasswordColor() {
      if (this.form.password !== "" && !this.$v.form.password.$error) {
        return "lock-password-line bold.svg";
      } else if (this.form.password !== "" && this.$v.form.password.$error) {
        return "lock-password-line error.svg";
      }
      return "lock-password-line fill.svg";
    },
    isDisable() {
      if (
        !this.$v.form.$anyError &&
        this.form.email !== "" &&
        this.form.password !== "" &&
        this.buttonLoader !== "loader"
      ) {
        if (this.showOTPBox) {
          if (this.otpDetails !== "") {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    },
  },
  watch: {
    "$v.form.$anyError"(val) {
      if (!val) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }
    },
  },

  methods: {
    AutoLogin(data) {
      this.loginCall(data)
    },
    googleOauth() {
      localStorage.setItem("googleOauth", true);
      window.location.href =
        "http://d27f1eb71758.ngrok.io/oauth2/authorize/google?redirect_uri=http://4170bf03167e.ngrok.io/welcome";
    },
    loginCall(details) {
      loginServices
        .login(details)
        .then((res) => {
          if (this.form.email.split("@")[1] === "datapoem.com") {
            sessionStorage.setItem("parentEmail", this.form.email);
          }
          if (res.data === "User is Not  Active and not Verified") {
            this.buttonLoader = "normal";
            this.showOTPBox = true;
          } else if (res.data === "User is not present") {
            this.errorMessage = "User is not present";
            this.buttonLoader = "normal";
          }
          if (res.data.accessToken) {
            sessionStorage.setItem("instance", "/b2c/in/");
            sessionStorage.setItem("Token", "Bearer " + res.data.accessToken);
            sessionStorage.setItem("dataPoemToken", "Bearer " + res.data.accessToken);
            sessionStorage.setItem("userName", res.data.userName);
            sessionStorage.setItem("clientId", res.data.email);
            sessionStorage.setItem("subId", res.data.subId);
            sessionStorage.setItem("userId", res.data.userId);
            sessionStorage.setItem('firstLogin', !res.data.loggedInStatus)
            localStorage.setItem("_key", JSON.stringify(details))

            this.landingPage = res.data.aimoLanding;
            this.role = res.data.userRoles;
            this.allTools = res.data.subscriberToolType;
            this.userEmail = res.data.email;
            this.getClientType(res.data.subId);
          }
        })
        .catch((error) => {
          // this.invalid = error.toString();
          // this.pageLoader = false;
          // this.error = error.toString();
          if (error.message === undefined) {
            this.errorMessage = error;
          } else {
            this.errorMessage = error.message;
          }

          this.buttonLoader = "normal";
        });
      // axios.post('https://datapoem.ai/api/user-module/authenticate', details)
      // .then((res)=>{
      //   if(res.data.accessToken){
      //     sessionStorage.setItem("dataPoemToken", "Bearer " + res.data.accessToken);
      //   }
      // })
    },
    verifyOtp() {
      loginServices.verifyOtp(this.form.email, this.otpDetails).then((res) => {
        if (res.status === "FAILED") {
          this.showOtpError = true;
          this.buttonLoader = "normal";
        } else {
          this.loginCall(this.form);
        }
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.buttonLoader = "loader";
      if (this.showOTPBox) {
        this.verifyOtp();
      } else {
        this.loginCall(this.form);
      }

      // this.$router.push("/welcome");
    },
    getClientType(orgId) {
      loginServices
        .getClientType(orgId)
        .then((res) => {
          sessionStorage.setItem("clientType", res.clientType);
          sessionStorage.setItem("agencyId", res.agencyId);
          sessionStorage.setItem("organization", res.organization)
          sessionStorage.setItem("country", res.country)
          sessionStorage.setItem(
            "tools",
            JSON.stringify(res.subscriberToolType)
          );
          if (this.landingPage === "" || this.landingPage === null) {
            sessionStorage.setItem("aimoLanding", res.aimoLanding);
          } else {
            sessionStorage.setItem("aimoLanding", this.landingPage);
          }

          if (this.role == "ADMIN") {
            sessionStorage.setItem("Role", "ADMIN");
            // this.$router.push("/dsv");
          } else if (this.role == "USER") {
            sessionStorage.setItem("Role", "USER");
          } else if (this.role == "MASTER_ADMIN") {
            sessionStorage.setItem("Role", "MASTER_ADMIN");
          } else if (this.role === "SUPER_ADMIN") {
            sessionStorage.setItem("Role", "SUPER_ADMIN");
          }
          this.$router.push("/welcome");
          // this.pageLoader = false;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.log(error);
          // this.pageLoader = false;
        });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {
    let details = JSON.parse(localStorage.getItem("_key"))
    document.addEventListener("keydown", ((event) => {
      if (event.which == 13) {
        this.onSubmit()
      }
    }))
    details !== null ? this.AutoLogin(details) : ""
    localStorage.removeItem("googleOauth");
    sessionStorage.clear();
  },
};
</script>

<style scoped>
.form-check {
  padding-left: 0;
}

.google-btn {
  height: 42px;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  cursor: pointer;
  align-self: center;
  user-select: none;
  transition: all 400ms ease 0s;
  display: flex;
}

.google-btn .google-icon-wrapper {
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  user-select: none;
}

.google-btn .google-icon-svg {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  user-select: none;
}

.google-btn .btn-text {
  float: right;
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Roboto;
  user-select: none;
}

.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}

.google-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #f8f8f8;
  color: #fff;
  user-select: none;
}

* {
  box-sizing: border-box;
}

body {
  /* background: #1C8EF9 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  /* font-family: 'Fira Sans', sans-serif; */

  /* width: 1520px;
  height: 1024px;
  padding: 32px 52px 21px 189.5px; */
  /* background-color: #f2f5fa; */
  /* background-color: #fff !important; */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  /* font-family: 'Fira Sans', sans-serif; */
}

body,
html,
#app,
#root,
.auth-wrapper {
  width: 100%;
  height: auto;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  /* width:450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px; */
  /* border-radius: 15px;
  transition: all .3s; */

  width: 576px;
  height: 860px;
  /* margin: 0 0 11px 0px; */
  margin-right: 50px;
  padding: 53px 78px 84px 79px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: #ffffff;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/* .Rectangle-Copy {
  width: 696px;
  height: 960px;
  margin: 40px 1px 16px 0;
  padding: 18px 15px 18px 16px;
  object-fit: contain;
  border-radius: 3px;
  background-color: var(--selection);
}  */
/* 
.Rectangle {
  width: 696px;
  height: 960px;
  margin: 0 0 11px 170.5px;
  padding: 153px 118px 284px 119px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: var(--white);
} */

.Bitmap {
  height: 85px;
  /* margin: 0 12px 39px 61px; */
  /* margin: 0 12px 39px 61px;*/
  object-fit: contain;
}

.Hello-welcome-back {
  width: 100%;
  height: 50px;
  /* margin: 180px 80px 153px 55px; */
  /* margin-top: 50px; */
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.Tech-Life-Life-Management {
  width: 300px;
  height: 300px;
  margin: 70px 31px 0px;
  padding: 42px 66px 43px 56px;
  object-fit: contain;
}

.Focused-on-results-n {
  /* width: 412px; */
  height: 100px;
  margin-right: 150px;
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.RectangleSmall {
  width: 32px;
  height: 8px;
  margin: 0 16px 0 130px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #222a37;
}

.Oval-Copy-2 {
  width: 8px;
  height: 8px;
  margin: 0 16px;
  object-fit: contain;
  border: solid 1px #222a37;
}

.Oval-Copy-3 {
  width: 8px;
  height: 8px;
  margin: 0 0 0 16px;
  object-fit: contain;
  border: solid 1px #222a37;
}

.inputIcon.invalid {
  color: #f8d8d8 !important;
  background-color: #f8d8d8 !important;
  border: 1px solid #dc3545;
  border-right: 0px;
}

.inputIcon.invalid.passwordIcon {
  border-right: 1px solid #dc3545;
}

.inputIcon.passwordIcon {
  border-right: 1px solid;
  cursor: pointer;
  border-left: 0px !important;
  border-radius: 3px;
}

.inputIcon.success {
  font-family: ProximaNovaBold;
  color: #3a424f;
}

.inputIcon {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #a3b6d9;
  background-color: #eff5ff;
  border: 1px solid;
  border-right: 0px;
  height: 60px;
  width: 10%;
  float: left;
}

.auth-wrapper .form-control:focus {
  border-color: #ced4da;
}

input {
  box-shadow: none !important;
  outline: none !important;
}

input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
  outline: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #a3b6d9 !important;
}

.form-control {
  border-top: 1px solid #3a424f;
  border-bottom: 1px solid #3a424f;
  box-shadow: none;
}

#exampleInputEmail1:focus,
#otpInputBox:focus {
  font-family: ProximaNovaBold;
  color: #3a424f;
}

#exampleInputEmail1,
#otpInputBox {
  height: 60px;
  object-fit: contain;
  padding: 18px 30px 18px 6px;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-image: none;
  color: #a3b6d9;
  /* color: #bccbe5; */
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #eff5ff;
  border-left: 0px;
}

#exampleInputEmail1:hover,
#otpInputBox:hover {
  color: #3a424f;
  background-color: #eff5ff;
}

/* #exampleInputEmail1:hover:before{
  opacity: 1;
}

#exampleInputEmail1{
  position: relative;
} */

::placeholder {
  color: #bccbe5;
  opacity: 1;
  /* Firefox */
}

::placeholder:hover {
  color: #888f9a;
  opacity: 1;
  /* Firefox */
}

#exampleInputPassword1 {
  height: 60px;
  object-fit: contain;
  background-image: none !important;

  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9aafd4; */
  background-color: #eff5ff;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  color: #9aafd4;
}

#exampleInputPassword1:hover {
  color: #3a424f;
  background-color: #eff5ff;
}

.person {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 385px;
  object-fit: contain;
  right: 450px;
  background-color: #f2f5fa;
}

/* .person_image{
 object-fit: contain;
  background-color: #f2f5fa;
} */
.password {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 485px;
  right: 450px;
  object-fit: contain;
  background-color: #f2f5fa;
}

.form-check-input {
  height: 24px;
  /* margin: 68px 11px 18px 0;
  margin-top: 90px; */
  object-fit: contain;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

.Remember-me {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  cursor: pointer;
  margin: 0px;
}

.btn-effect {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect-valid {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}

.Forgot-your-password {
  width: 100%;
  height: 20px;
  right: 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  margin-top: 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  text-decoration: underline;

  /* color: blueviolet; */
}

.Forgot-your-password>span {
  cursor: pointer;
}

.isi {
  width: 190px;
  height: 60px;
  margin: 0 0 0 12px;
  object-fit: contain;
  opacity: 0.5;
}

.Copyright-2021-Dat {
  width: 434px;
  height: 16px;
  /* margin: 153px 200.5px 0 0; */
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  left: -30px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

.bg-img {
  position: absolute;
  top: 0px;
  width: 228px;
  height: 265px;
  background-image: "./assets/Icons/path-17.svg";
}

.eye-line {
  position: absolute;
  top: 538px;
  width: 24px;
  height: 24px;
  margin: 0 0 0 416px;
  object-fit: contain;
  color: #9aafd4;
  cursor: pointer;
}

.eye-line:hover {
  color: #3a424f;
}

.c-form-input.is-invalid {
  background-color: #f8d8d8 !important;
}

.invalid-feedback {
  font-size: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  box-shadow: none;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #3a424f !important;
}

@media screen and (max-width: 768px) {
  .auth-inner {
    width: 100%;
  }
}
</style>
